import * as React from "react";
import Dialog from "@mui/material/Dialog";

import {
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Close = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
  padding: "1.5rem",
}));


const SearchQueryModal = ({
  open,
  onClose,
  bugdata,
}: {
  open: any;
  onClose: any;
  bugdata: any;
}) => {
  const filterData = Object.keys(bugdata);
  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          Applied filters
          <Close aria-label="close" onClick={onClose}>
            <CloseIcon />
          </Close>
        </DialogTitle>
        <Container>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Properties</TableCell>
                  <TableCell align="right">value</TableCell>
                  <TableCell align="right">min</TableCell>
                  <TableCell align="right">max</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filterData?.map((property: string, index: number) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right">{property}</TableCell>
                    <TableCell align="right">
                      {typeof bugdata[property] === "object"
                        ? bugdata[property].minimum > -1 ||
                          bugdata[property].start
                          ? "range"
                          : bugdata[property].join(",")
                        : bugdata[property]}
                    </TableCell>
                    {bugdata[property].minimum > -1 ? (
                      <>
                        <TableCell align="right">
                          {bugdata[property].minimum}
                        </TableCell>
                        <TableCell align="right">
                          {bugdata[property].maximum}
                        </TableCell>
                      </>
                    ) : undefined}
                    {bugdata[property].start ? (
                      <>
                        <TableCell align="right">
                          {bugdata[property].start}
                        </TableCell>
                        <TableCell align="right">
                          {bugdata[property].end}
                        </TableCell>
                      </>
                    ) : undefined}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Container>
      </Dialog>
    </div>
  );
};

export default SearchQueryModal;
