/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { ArrowDropDown, NavigateNext } from "@mui/icons-material";
import {
	Box,
	Breadcrumbs,
	Chip,
	debounce,
	Divider,
	Link,
	MenuItem,
	Select,
	SelectChangeEvent,
	Typography,
} from "@mui/material";
import { useRouter } from "next/router";
import ReportModal from "../../../modal/reportmodal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "resolved"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "unresolved"
			? {
					backgroundColor: "#d5d5d7",
					color: "#000000",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const ReportDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	const [openModal, setOpenMoadal] = React.useState(false);

	const [openReport, setOpenReportModal] = React.useState(false);

	const [reportdata, setReportData] = React.useState<any>([]);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);

	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */
	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	/*

   /*

  & Next, let's define a function to handle the change in the select and update the status

  */

	const handleChange = async (event: SelectChangeEvent, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "report-table/resolved-unresolved", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
			},
			body: JSON.stringify({
				status: event.target.value,
				ReportId: cellValues.id,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		setOpenMoadal(false);
	};

	const buginfo = async (cellValues: any) => {
		setReportData(cellValues);
		setOpenReportModal(true);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "report-table/report-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const menuItems = [
		{
			id: 1,
			label: "Unresolved",
			value: "unresolved",
		},
		{
			id: 2,
			label: "Resolved",
			value: "resolved",
		},
	];

	const columns = [
		{
			field: "userid",
			headerName: "User ID",
			width: 200,
		},
		{
			field: "userName",
			headerName: "User Name",
			width: 150,
		},

		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 150,
		},
		{
			field: "browser",
			headerName: "Browser",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								cellValues.value === "Chrome"
									? "/images/icons/logo/🦆 icon _chrome_.svg"
									: cellValues.value === "Duck"
										? "/images/icons/logo/Duck Duck Go.svg"
										: cellValues.value === "Brave"
											? "/images/icons/logo/Brave.svg"
											: cellValues.value === "Edge"
												? "/images/icons/logo/Edge.svg"
												: cellValues.value === "Firefox"
													? "/images/icons/logo/Firefox.svg"
													: cellValues.value === "Opera"
														? "/images/icons/logo/Opera.svg"
														: cellValues.value?.includes("Safari")
															? "/images/icons/logo/Safari.svg"
															: cellValues.value === "Samsung"
																? "/images/icons/logo/Samsung Browser.svg"
																: cellValues.value === "undici"
																	? "/images/icons/logo/nodejs-icon.svg"
																	: cellValues.value === "Internet Explorer"
																		? "/images/icons/logo/Internet Explorer.svg"
																		: cellValues.value === "iOS"
																			? "/images/icons/logo/Apple.svg"
																			: "/images/icons/logo/🦆 icon _chrome_.svg"
							}
							alt="browser"
						/>
						<Typography variant="body2">{cellValues.value}</Typography>
					</ActionActions>
				);
			},
		},
		{
			field: "operating_system",
			headerName: "Operating System",
			width: 180,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								cellValues.value === "Windows"
									? "/images/icons/logo/Windows 11.svg"
									: cellValues.value === "MacOS"
										? "/images/icons/logo/Apple (1).svg"
										: cellValues.value?.includes("iOS")
											? "/images/icons/logo/Apple.svg"
											: cellValues.value?.includes("macOS")
												? "/images/icons/logo/Apple (1).svg"
												: cellValues.value === "Linux"
													? "/images/icons/logo/Linux.svg"
													: cellValues.value?.includes("Android")
														? "/images/icons/logo/android.svg"
														: "/images/icons/logo/Windows 11.svg"
							}
							alt="os"
						/>
						<Typography variant="body2">{cellValues.value}</Typography>
					</ActionActions>
				);
			},
		},

		{
			field: "url",
			headerName: "URL",
			width: 240,
			renderCell: (cellValues: any) => {
				return (
					<Link
						href={"https://beegru.com/" + cellValues.value}
						target="_blank"
						sx={{
							textDecoration: "none",
						}}
					>
						{cellValues.value}
					</Link>
				);
			},
		},
		{
			field: "reporttype",
			headerName: "Report Type",
			width: 120,
		},
		// {
		//   field: "reason",
		//   headerName: "Reason",
		//   width: 200,
		// },
		{
			field: "reportinfo",
			headerName: "Report Info",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<Link
						onClick={() => {
							buginfo(cellValues.row);
						}}
						sx={{
							textDecoration: "none",
							cursor: "pointer",
						}}
					>
						Report Info
					</Link>
				);
			},
		},
		{
			field: "status",
			headerName: "Status",
			editable: true,
			width: 150,
			// type: "singleSelect",
			valueOptions: menuItems,
			valueFormatter: (params: any) => {
				if (!params) {
					return "";
				}
				return params.value === "resolved" ? "Resolved" : params.value === "unresolved" ? "Unresolved" : "";
			},
			renderCell: (cellValues: any) => {
				const data = dashboardData.find((property: any) => property._id === cellValues.row.id);
				return (
					<ChipData
						id={data ? data.status : cellValues.value}
						label={
							(data ? data.status : cellValues.value) === "unresolved"
								? "Unresolved"
								: (data ? data.status : cellValues.value) === "resolved"
									? "Resolved"
									: ""
						}
						variant="filled"
						onDelete={() => {}}
						deleteIcon={<ArrowDropDown />}
					/>
				);
			},
			renderEditCell: (cellValues: any) => {
				const data = dashboardData.find((property: any) => property._id === cellValues.row.id);
				return (
					<Select
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						fullWidth={true}
						value={data ? data.status : cellValues.value}
						onChange={(event: any) => handleChange(event, cellValues)}
					>
						{menuItems.map((item: any, index: number) => {
							return (
								<MenuItem
									value={item.value}
									key={index}
								>
									{item.label}
								</MenuItem>
							);
						})}
					</Select>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Reports
		</Typography>,
	];

	console.log(dashboardData);

	return (
		<MainContainer>
			<ReportModal
				open={openReport}
				onClose={() => setOpenReportModal(false)}
				bugdata={reportdata}
			/>
			<HeadContainer>
				<HeadText>Reports</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("report")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any) => {
								return {
									id: item._id,
									userid: item.created_by
										? item.created_by.is_business_profile
											? item.created_by.business_profile_id
												? item.created_by.business_profile_id._id
												: "not logged in"
											: item.created_by.user_id
												? item.created_by.user_id._id
												: "not logged in"
										: "not logged in",

									userName: item.created_by
										? item.created_by.is_business_profile
											? item.created_by.business_profile_id
												? item.created_by.business_profile_id.title
												: "no name"
											: item.created_by.user_id
												? item.created_by.user_id.first_name + " " + item.created_by.user_id.last_name
												: "no name"
										: "no name",
									browser: item.user_agent
										? item.user_agent.browser
											? item.user_agent.browser.name
											: "Chrome"
										: "Chrome",
									operating_system: item.user_agent?.os.name || "Windows 10",
									phonenumber: item.created_by
										? item.created_by.is_business_profile
											? item.created_by.business_profile_id
												? item.created_by.business_profile_id.phone
													? item.created_by.business_profile_id.phone.country_code +
														" " +
														item.created_by.business_profile_id.phone.phone_number
													: "no phone number"
												: "no phone number"
											: item.created_by.user_id
												? item.created_by.user_id.country_code + " " + item.created_by.user_id.phone
												: "no phone number"
										: "no phone number",
									reporttype: item.report_type,
									url: item.report_type?.includes("Automated")
										? item.url.replace("https://beegru.com/", "/")
										: item.url,
									reason: item.description,
									reportinfo: item.buginfo,
									status: item.status,
									createdOn: item.created_at,
								};
							})}
							// rows={rows}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
							sx={{
								backgroundColor: "#FFFFFF",
								"& > .MuiDataGrid-columnSeparator": {
									display: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default ReportDataTable;
