import * as React from "react";
import Dialog from "@mui/material/Dialog";

import {
  Button,
  DialogTitle,
  IconButton,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Close = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  right: theme.spacing(0.5),
  top: theme.spacing(0.5),
  color: theme.palette.grey[500],
}));

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  width: "100%",
  gap: "1.5rem",
}));

const TextContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  gap: "0.25rem",
}));

const MessageBoxContainer = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "1rem",
  wordSpacing: "0.5rem",
  [theme.breakpoints.only("sm")]: {
    width: "100%",
  },
}));

const MessageContainer = styled(Paper)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-end",
  boxShadow: " 0px 1px 3px rgba(0, 0, 0, 0.12)",
  width: "100%",
  borderRadius: "8px",
  padding: "1rem",
  background: theme.palette.background.paper,
  [theme.breakpoints.only("sm")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
}));

const ReminderForBusinessModal = ({
  open,
  onClose,
  userId,
}: {
  open: any;
  onClose: any;
  userId: any;
}) => {
  const [message, setMessage] = React.useState("");

  const handleChangeMessage = (value: any) => {
    setMessage(value);
  };

  

  const submitMessage = async () => {
    await fetch(process.env.PRODUCTION_API_URL + "user-reminder", {
      method: "POST",
      cache: "no-store",
      headers: {
        "Content-Type": "text/plain",
        "x-vercel-protection-bypass":
          process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
      },
      body: JSON.stringify({
        userId: userId ? userId : "",
        message: message,
      }),
    });
    setMessage("");
  };

  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h6" component="div">
            Send a mail to the business profile
          </Typography>
          <Close aria-label="close" onClick={onClose}>
            <CloseIcon />
          </Close>
        </DialogTitle>
        <Container>
          <MessageContainer>
            <MessageBoxContainer>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={message}
                placeholder="Enter your message here"
                onChange={(event: any) => {
                  handleChangeMessage(event.target.value);
                }}
              />
            </MessageBoxContainer>
            <Button
              disabled={message.length === 0}
              variant="contained"
              color="primary"
              onClick={() => {
                submitMessage();
                onClose();
              }}
            >
              Send
            </Button>
          </MessageContainer>
        </Container>
      </Dialog>
    </div>
  );
};

export default ReminderForBusinessModal;
