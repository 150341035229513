import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { CircularProgress, styled } from "@mui/material";

const MainContainer = styled(DialogContentText)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  gap: "1rem",
}));

const LoadingModal = ({ open }: { open: any }) => {
  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <MainContainer>
            <CircularProgress /> Please wait while we load the data...
          </MainContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default LoadingModal;
