/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we need on this page

*/

import { getServerSession } from "next-auth/next";
import { useRouter } from "next/router";
import Layout from "../components/layout-components/Layout";
import HomeDashboard from "../components/page-components/HomeDashboard";
import { authOptions } from "./api/auth/[...nextauth]";

/*

? Next, let's import all the UI components we need from MUI

*/

import { Button, Typography } from "@mui/material";

/*

? Next, let's import all the icons we need in this component

*/

import { Google } from "@mui/icons-material";

import { signIn, signOut } from "next-auth/react";

/*

& Next, let's style the default component on the page

*/

const DefaultComponent = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "calc(100vh - 8.5625rem)",
}));

const DefaultComponentTitle = styled(Typography)(({ theme }) => ({
	fontSize: "3rem",
	fontWeight: 500,
	color: "#000000",
	marginBottom: "1rem",
	width: "100%",
	textAlign: "center",
})) as typeof Typography;

const DefaultComponentSubtitle = styled(Typography)(({ theme }) => ({
	fontSize: "1rem",
	fontWeight: 400,
	color: "#000000",
	marginBottom: "1rem",
	width: "50%",
	textAlign: "center",
})) as typeof Typography;

/*

& Next, let's describe the component

*/

export async function getServerSideProps(context: any) {
	// Fetch data from external API

	const res = await fetch(process.env.PRODUCTION_API_URL + "dashboard", {
		method: "POST",
		cache: "no-store",
		headers: {
			"Content-Type": "text/plain",
			"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
		},
		body: JSON.stringify({
			...context.query,
			email: "",
		}),
	});
	const data = await res.json();
	const session: any = await getServerSession(context.req, context.res, authOptions);
	// Pass data to the page via props
	return { props: { dataFromServer: data, sessionFromServer: session } };
}

const Index = ({
	dataFromServer: data,
	sessionFromServer: session,
}: {
	dataFromServer: any;
	sessionFromServer: any;
}) => {
	const [DashboardData, setDashboardData] = React.useState<any>(data?.payload ? data?.payload : []);

	const router = useRouter();

	React.useEffect(() => {
		const res = async () => {
			const data = await (
				await fetch(process.env.PRODUCTION_API_URL + "dashboard", {
					method: "POST",
					cache: "no-store",
					headers: {
						"Content-Type": "text/plain",
						"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
					},
					body: JSON.stringify({
						...router.query,
						email: session ? (session.user ? session.user.email : "") : "",
					}),
				})
			).json();
			setDashboardData(data.payload ? data.payload : []);
		};
		res();
		const q = router.query;
		if (q.change) {
			delete q.change;
			router.push(
				{
					pathname: router.pathname,
					query: q,
				},
				undefined,
				{
					shallow: true,
				},
			);
		}
		if (!q.tab) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...q,
						tab: "property",
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [router.query]);

	return (
		<React.Fragment>
			<Layout
				hideStartMargin
				hideEndMargin
				marginalize="3rem"
				session={session}
			>
				{session ? (
					<HomeDashboard
						DashboardData={DashboardData}
						session={session}
						unboundBackground="#FAFAFA"
					/>
				) : (
					<DefaultComponent>
						<DefaultComponentTitle
							variant="h1"
							component="h1"
						>
							{"You need to sign in to gain access to the control panel."}
						</DefaultComponentTitle>
						<DefaultComponentSubtitle
							variant="body2"
							component="p"
						>
							{
								"Click the button below to sign in with your Beegru email address. Make sure that you have a marketplace account with the same recovery email address and that you have been granted access to the control panel."
							}
						</DefaultComponentSubtitle>
						<Button
							startIcon={<Google />}
							variant="contained"
							size="large"
							color="primary"
							sx={{
								textTransform: "none",
								color: "#FFFFFF",
							}}
							onClick={() => {
								session
									? session.user
										? signOut()
										: signIn("google", {
												callbackUrl: process.env.PRODUCTION_URL || "",
										  })
									: signIn("google", {
											callbackUrl: process.env.PRODUCTION_URL || "",
									  });
							}}
						>
							{session ? (session.user ? "Sign Out" : "Sign in with Google") : "Sign In with Google"}
						</Button>
					</DefaultComponent>
				)}
			</Layout>
		</React.Fragment>
	);
};

/*

& FInally, let's export the component

*/

export default Index;
