/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridFilterModel,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Chip, debounce, Divider, Link, Switch, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import LoadingModal from "../../../modal/loading";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipData = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "user"
		? {
				backgroundColor: "rgba(244, 67, 54, 0.5)",
			}
		: id === "agent"
			? {
					backgroundColor: "#FFD700",
				}
			: id === "developer"
				? {
						backgroundColor: "#d5d5d7",
					}
				: id === "landowner"
					? {
							backgroundColor: "rgb(30,144,255,0.6)",
						}
					: id === "professional"
						? {
								backgroundColor: " #00FF00",
							}
						: {
								backgroundColor: "rgba(255, 255, 255, 0.002)",
							},
);

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const PostsDatatable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	const [openModal, setOpenMoadal] = React.useState(false);

	const [dashboardData, setDashboardData] = React.useState(DashboardData.DashboardData || []);
	const [queryOptions, setQueryOptions] = React.useState<GridFilterModel>({ items: [] });

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		if (queryOptions.items.length === 0) {
			router.push(
				{
					pathname: router.pathname,
					query: {
						...router.query,
						page: paginationModel.page,
					},
				},
				undefined,
				{
					shallow: true,
				},
			);
		} else {
			FilteringFunction(queryOptions);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount);
		setDashboardData(DashboardData.DashboardData || []);
	}, [DashboardData]);

	/*

  & Next, let's define a function to handle the change in the select and update the assigned to

  */

	const deletePost = async (event: any, cellValues: any) => {
		setOpenMoadal(true);
		await fetch(process.env.PRODUCTION_API_URL + "post-table/delete-post", {
			method: "POST",
			cache: "no-store",
			headers: {
				"Content-Type": "text/plain",
				"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
			},
			body: JSON.stringify({
				delete: event,
				postId: cellValues,
			}),
		});
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					change: "true",
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		setOpenMoadal(false);
	};

	const fetchData = React.useCallback(
		async (newFilterModel: GridFilterModel) => {
			const data = await fetch(process.env.PRODUCTION_API_URL + "post-table/post-filtering", {
				method: "POST",
				cache: "no-store",
				headers: {
					"Content-Type": "text/plain",
					"x-vercel-protection-bypass": process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
				},
				body: JSON.stringify({
					...router.query,
					email: session ? (session.user ? session.user.email : "") : "",
					filter: newFilterModel,
					page: paginationModel.page,
				}),
			});

			const res = await data.json();
			setDashboardData(res.payload.DashboardData);
			setRowCountState(res.payload.rowCount);
		},
		[router.query, session, paginationModel.page],
	);

	const debouncedFetchData = React.useMemo(() => debounce(fetchData, 500), [fetchData]);

	const FilteringFunction = (newFilterModel: GridFilterModel) => {
		debouncedFetchData(newFilterModel);
	};

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "postId",
			headerName: "Post Id",
			width: 200,
		},
		{
			field: "creatorName",
			headerName: "Creator Name",
			width: 200,
			// renderCell: (cellValues: any) => {
			//   return (
			//     <Link
			//       href="/"
			//       sx={{
			//         textDecoration: "none",
			//       }}
			//     >
			//       {cellValues.value}
			//     </Link>
			//   );
			// },
		},
		{
			field: "phonenumber",
			headerName: "Phone Number",
			width: 150,
		},
		{
			field: "createdOn",
			headerName: "Account created on",
			width: 150,
			filterable: false,
		},

		// {
		//   field: "lastSeen",
		//   headerName: "Last seen",
		//   width: 100,
		// },
		// {
		//   field: "creditDebit",
		//   headerName: "Credit/Debit",
		//   width: 100,
		//   renderCell: (cellValues: any) => {
		//     return (
		//       <ActionActions>
		//         <IconButton
		//           onClick={() => {
		//             setAdd("add");
		//             AddBeegruPoints(cellValues.row);
		//           }}
		//         >
		//           <AddCircleIcon />
		//         </IconButton>
		//         <IconButton
		//           onClick={() => {
		//             setAdd("remove");
		//             AddBeegruPoints(cellValues.row);
		//           }}
		//         >
		//           <RemoveCircleIcon />
		//         </IconButton>
		//       </ActionActions>
		//     );
		//   },
		// },
		// {
		//   field: "bannedUsers",
		//   headerName: "Banned Users",
		//   width: 120,
		//   renderCell: (cellValues: any) => {
		//     return (
		//       <Switch
		//         checked={cellValues.value}
		//         onChange={(e) => {
		//           setUserId(cellValues.row.id);
		//           setUsername(cellValues.row.username);
		//           setBannedUserModal(true);
		//         }}
		//       />
		//     );
		//   },
		// },
		// {
		//   field: "location",
		//   headerName: "Location",
		//   width: 150,
		// },
		{
			field: "booms",
			headerName: "Booms",
			width: 150,
		},
		{
			field: "blasts",
			headerName: "Blasts",
			width: 100,
		},
		{
			field: "blasted_post",
			headerName: "Blasted Post",
			width: 200,
		},
		{
			field: "delete",
			headerName: "delete",
			width: 100,
			editable: true,
			renderCell: (cellValues: any) => {
				return (
					<Switch
						checked={cellValues.value}
						onChange={(e) => {
							deletePost(e.target.checked, cellValues.row.id);
						}}
					/>
				);
			},
		},
		{
			field: "action",
			headerName: "Action",
			width: 200,
			filterable: false,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Link
							href={"tel:" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/call_blue.svg"
										: "/images/icons/social/call_blue.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://wa.me/" + cellValues.row.phonenumber}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={
									theme.palette.mode === "dark"
										? "/images/icons/social/whatsapp_green.svg"
										: "/images/icons/social/whatsapp_green.svg"
								}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
						<Link
							href={"https://beegru.com/post/" + cellValues.row.postId}
							target="_blank"
							rel="noopener"
							sx={{
								textDecoration: "none",
							}}
						>
							<ImgTag
								src={theme.palette.mode === "dark" ? "/images/icons/social/edit.svg" : "/images/icons/social/edit.svg"}
								alt="Share icon"
								loading="lazy"
								referrerPolicy="no-referrer"
							/>
						</Link>
					</ActionActions>
				);
			},
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="2"
			color="text.primary"
		>
			Post
		</Typography>,
	];

	return (
		<MainContainer>
			<LoadingModal open={openModal} />
			<HeadContainer>
				<HeadText>Posts</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("post")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={dashboardData?.map((item: any) => {
								const date = new Date(item.created_at);

								const userNumber = item.creator_id
									? item.creator_id.business_profile_id.userphone
										? item.creator_id.business_profile_id.userphone.country_code +
											" " +
											item.creator_id.business_profile_id.userphone.phone
										: item.creator_id.business_profile_id.phone
											? item.creator_id.business_profile_id.phone.country_code +
												" " +
												item.creator_id.business_profile_id.phone.phone_number
											: ""
									: "";

								const userNumber1 = item.creator_id
									? item.creator_id.user_id
										? item.creator_id.user_id.country_code + " " + item.creator_id.user_id.phone
										: ""
									: "";

								return {
									id: item._id,
									postId: item._id,
									creatorName: item.creator_id?.is_business_profile
										? item.creator_id?.business_profile_id?.title
										: item.creator_id?.user_id
											? item.creator_id?.user_id.first_name + " " + item.creator_id?.user_id.last_name
											: "",
									phonenumber: item.creator_id ? (item.creator_id.is_business_profile ? userNumber : userNumber1) : "",

									createdOn: date.toLocaleDateString(),
									booms: item.booms?.length,
									blasts: item.blasts?.length,
									blasted_post: item.is_blasted,
									delete: item.deleted,
								};
							})}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							filterMode="server"
							onFilterModelChange={(newFilterModel) => {
								setQueryOptions(newFilterModel);
								FilteringFunction(newFilterModel);
							}}
							sx={{
								backgroundColor: "#FFFFFF",
								"& > .MuiDataGrid-columnSeparator": {
									display: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default PostsDatatable;
