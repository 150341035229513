import React from "react";
import Dialog from "@mui/material/Dialog";

import {
  Button,
  DialogActions,
  FormControl,
  RadioGroup,
  Typography,
  styled,
  Radio,
  FormLabel,
  FormControlLabel,
  TextField,
} from "@mui/material";

import { useRouter } from "next/router";

const Image = styled("img")(({ theme }) => ({
  objectFit: "contain",
  width: "100%",
  height: "12.5rem",
}));

const ImgDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "space-between",
  width: "100%",
  gap: "1rem",
}));

const SubHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: "2rem",
  fontSize: "1rem",
  padding: "0rem 0rem 0.5rem 0rem",
}));

const Heading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: "2rem",
  fontSize: "1.125rem",
  padding: "0.5rem 0rem 0.5rem 1.5rem",
}));

const MainHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: "1.125rem",
  padding: "1.5rem 1.5rem 1rem 1.5rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
}));

const ImageContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  width: "100%",
  padding: "0rem 1.5rem 0rem 1.5rem",
}));

const TextFieldDiv = styled("div")(({ theme }) => ({
  padding: "0rem 1.5rem 1.5rem 1.5rem",
}));

const ContentDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "flex-start",
  width: "100%",
}));

const Container = styled("div")(({ theme }) => ({
  margin: "1rem 0rem 0rem 0rem",
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
}));

const KycApprovalModal = ({
  open,
  onClose,
  data,
}: {
  open: any;
  onClose: any;
  data: any;
}) => {
  const router = React.useRef(useRouter()).current;

  const submit = async () => {
    await fetch(
      process.env.PRODUCTION_API_URL + "kyc-table/user/kyc-approval",
      {
        method: "POST",
        cache: "no-store",
        headers: {
          "Content-Type": "text/plain",
          "x-vercel-protection-bypass":
            process.env.X_VERCEL_PROTECTION_BYPASS_SECRET || "",
        },
        body: JSON.stringify({
          userId: data.userId,
          status: value,
          reason: reason,
        }),
      }
    );
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router.query,
          change: "true",
        },
      },
      undefined,
      {
        shallow: true,
      }
    );
    onClose();
  };

  const [identityDocument, setIdentityDocument] = React.useState<string>(
    data ? (data?.action ? data?.action?.selected_document : "") : ""
  );

  const [value, setValue] = React.useState(data ? data.status : "");

  const [reason, setReason] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  React.useEffect(() => {
    if (data) {
      setValue(data.status);
      if (data.action) {
        if (data.action.selected_document) {
          setIdentityDocument(data.action.selected_document);
        }
      }
    }
  }, [data]);

  let selectedDocsNumber: any = 0;

  switch (identityDocument) {
    case "Aadhar Card":
      selectedDocsNumber = data.action.aadhaar.aadhaar_number;
      break;
    case "Driving License":
      selectedDocsNumber = data.action.driving_license.driving_license_number;
      break;
    case "Voter ID":
      selectedDocsNumber = data.action.voter_id.voter_id_number;
      break;
    case "Passport":
      selectedDocsNumber = data.action.passport.passport_number;
      break;
    default:
      break;
  }

  return (
    <div>
      <Dialog
        open={Boolean(open)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={onClose}
        fullWidth={true}
        maxWidth="md"
      >
        <MainHeading>KYC Approval</MainHeading>
        <Heading>Pan card : {data?.action?.pan?.pan_number}</Heading>

        <ImageContainer>
          <ImgDiv>
            <ContentDiv>
              <SubHeading>Pan Front Side</SubHeading>
              <Image
                src={data?.action?.pan?.front_image_file_id?.regular}
                alt=""
              />
            </ContentDiv>
          </ImgDiv>
        </ImageContainer>

        <Container>
          <Heading>Selected Document :</Heading>
          <Heading>{data?.action?.selected_document}</Heading>
          <Heading>{data?.action?.selected_document}:</Heading>
          <Heading>{selectedDocsNumber}</Heading>
        </Container>


        {identityDocument === "Aadhar Card" ? (
          <ImageContainer>
            <ImgDiv>
              <ContentDiv>
                <SubHeading>Aadhar Front Side</SubHeading>
                <Image
                  src={data?.action?.aadhaar?.front_image_file_id?.regular}
                  alt=""
                />
              </ContentDiv>
              <ContentDiv>
                <SubHeading>Aadhar Back Side</SubHeading>
                <Image
                  src={data?.action?.aadhaar?.back_image_file_id?.regular}
                  alt=""
                />
              </ContentDiv>
            </ImgDiv>
          </ImageContainer>
        ) : null}

        {identityDocument === "Driving License" ? (
          <ImageContainer>
            <ImgDiv>
              <ContentDiv>
                <SubHeading>DL Front Side</SubHeading>
                <Image
                  src={
                    data?.action?.driving_license?.front_image_file_id?.regular
                  }
                  alt=""
                />
              </ContentDiv>
              <ContentDiv>
                <SubHeading>DL Back Side</SubHeading>
                <Image
                  src={
                    data?.action?.driving_license?.back_image_file_id?.regular
                  }
                  alt=""
                />
              </ContentDiv>
            </ImgDiv>
          </ImageContainer>
        ) : null}

        {identityDocument === "Voter ID" ? (
          <ImageContainer>
            <ImgDiv>
              <ContentDiv>
                <SubHeading>Voter Id Front Side</SubHeading>
                <Image
                  src={data?.action?.voter_id?.front_image_file_id?.regular}
                  alt=""
                />
              </ContentDiv>
              <ContentDiv>
                <SubHeading>Voter Id Back Side</SubHeading>
                <Image
                  src={data?.action?.voter_id?.back_image_file_id?.regular}
                  alt=""
                />
              </ContentDiv>
            </ImgDiv>
          </ImageContainer>
        ) : null}

        {identityDocument === "Passport" ? (
          <ImageContainer>
            <ImgDiv>
              <ContentDiv>
                <SubHeading>Passport Front Side</SubHeading>
                <Image
                  src={data?.action?.passport?.front_image_file_id?.regular}
                  alt=""
                />
              </ContentDiv>
              <ContentDiv>
                <SubHeading>Passport Back Side</SubHeading>
                <Image
                  src={data?.action?.passport?.front_image_file_id?.regular}
                  alt=""
                />
              </ContentDiv>
            </ImgDiv>
          </ImageContainer>
        ) : null}

        <FormControl sx={{ padding: "1.5rem 1.5rem 1.5rem 1.5rem" }}>
          <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="awaiting_documents"
              control={<Radio />}
              label="Awaiting Documents"
            />
            <FormControlLabel
              value="kyc_pending"
              control={<Radio />}
              label="KYC Pending"
            />
            <FormControlLabel
              value="kyc_completed"
              control={<Radio />}
              label="KYC Completed"
            />
          </RadioGroup>
        </FormControl>

        {value === "kyc_completed" ? null : (
          <TextFieldDiv>
            <TextField
              id="standard-basic"
              label="Reason"
              variant="standard"
              value={reason}
              onChange={(event) => {
                setReason(event.target.value);
              }}
              sx={{ width: "100%" }}
            />
          </TextFieldDiv>
        )}

        <DialogActions>
          <Button autoFocus onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={submit}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default KycApprovalModal;
