/*

? First, let's import the essentials - React and Styled

*/

import { styled } from "@mui/system";
import React from "react";

/*

? Next, let's import all the UI components we intend to use on this page from Mui.

*/

import {
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExport,
	GridToolbarFilterButton,
} from "@mui/x-data-grid-premium";
import { DataGridPremium } from "@mui/x-data-grid-premium/DataGridPremium";
import { LicenseInfo } from "@mui/x-license";

import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, Button, Chip, Divider, Link, Typography, useTheme } from "@mui/material";
import { useRouter } from "next/router";
import SearchQueryModal from "../modal/searchQueryModal";

/*

*MUI_X_PRO_LICENSE_KEY

*/

LicenseInfo.setLicenseKey(process.env.MUI_X_PREMIUM_KEY || "");

/*

& Next, let's style all the components we intend to use on this page.

*/

type CustomAttributesForDivElement = {
	id?: string;
};

const ChipDataForTransactionType = styled(Chip, {
	shouldForwardProp: (prop: any) => prop !== "id",
})<CustomAttributesForDivElement>(({ id, theme }) =>
	id === "paid"
		? {
				backgroundColor: "rgba(76, 175, 80, 0.5)",
			}
		: id === "failed"
			? {
					backgroundColor: "rgba(244, 67, 54, 0.5)",
				}
			: {
					backgroundColor: "rgba(255, 255, 255, 0.002)",
				},
);

const ActionActions = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "flex-start",
	alignItems: "center",
	gap: "0.5rem",
}));

const YouDontHaveAccess = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	width: "100%",
	height: "80vh",
}));

const ImgTag = styled("img")(({ theme }) => ({
	height: "2rem",
	width: "2rem",
}));

const HeadContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
	padding: "1rem 0rem",
}));

const HeadText = styled(Typography)(({ theme }) => ({
	fontSize: "1.5rem",
	fontWeight: "400",
	lineHeight: "123.5%",
	padding: "0rem 0rem 0.5rem 0rem",
	margin: "0rem 0rem 0rem 1rem",
}));

const MainContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	justifyContent: "flex-start",
	alignItems: "flex-start",
	width: "100%",
}));

const SearchLogDataTable = ({ DashboardData, session }: { DashboardData: any; session: any }) => {
	const theme = useTheme();

	/*

  & Next, let's define a local state for the modal

  */

	const [openModal, setOpenMoadal] = React.useState(false);

	const [searchQueryData, setSearchQueryData] = React.useState({});

	/*

  & Next, let's define a const for the router, so that we can use it to push the query params. useRef is used to prevent the router from re-rendering

  */

	const router = React.useRef(useRouter()).current;

	/*

  & Next, let's define a local state for the pagination model

  */

	const [paginationModel, setPaginationModel] = React.useState({
		page: 0,
		pageSize: 100,
	});

	/*

  & Next, let's define a local state for the row count

  */

	const [rowCountState, setRowCountState] = React.useState(DashboardData.rowCount);

	/*

  & Next, let's define a useEffect to update the page query param when the pagination model changes

  */

	React.useEffect(() => {
		router.push(
			{
				pathname: router.pathname,
				query: {
					...router.query,
					page: paginationModel.page,
				},
			},
			undefined,
			{
				shallow: true,
			},
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paginationModel.page]);

	/*

  & Next, let's define a useEffect to update the row count when the DashboardData changes

  */

	React.useEffect(() => {
		setRowCountState(DashboardData.rowCount);
	}, [DashboardData]);

	/*

  & Next, let's define the column data for the table

  */

	const columns = [
		{
			field: "_id",
			headerName: " ID",
			width: 150,
		},
		{
			field: "search_duration",
			headerName: "Search Duration",
			width: 150,
		},
		{
			field: "result_count",
			headerName: "Result Count",
			width: 150,
		},
		{
			field: "location",
			headerName: "Location",
			width: 150,
		},
		{
			field: "search_type",
			headerName: "Search Type",
			width: 150,
		},
		{
			field: "search_text",
			headerName: "Search Text",
			width: 150,
		},
		{
			field: "transaction_type",
			headerName: "Transaction Type",
			width: 150,
		},
		{
			field: "ip_address",
			headerName: "IP Address",
			width: 150,
		},
		{
			field: "browser",
			headerName: "Browser",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								cellValues.value === "Chrome"
									? "/images/icons/logo/🦆 icon _chrome_.svg"
									: cellValues.value === "Duck"
										? "/images/icons/logo/Duck Duck Go.svg"
										: cellValues.value === "Brave"
											? "/images/icons/logo/Brave.svg"
											: cellValues.value === "Edge"
												? "/images/icons/logo/Edge.svg"
												: cellValues.value === "Firefox"
													? "/images/icons/logo/Firefox.svg"
													: cellValues.value === "Opera"
														? "/images/icons/logo/Opera.svg"
														: cellValues.value?.includes("Safari")
															? "/images/icons/logo/Safari.svg"
															: cellValues.value === "Samsung"
																? "/images/icons/logo/Samsung Browser.svg"
																: cellValues.value === "undici"
																	? "/images/icons/logo/nodejs-icon.svg"
																	: cellValues.value === "Internet Explorer"
																		? "/images/icons/logo/Internet Explorer.svg"
																		: cellValues.value === "iOS"
																			? "/images/icons/logo/Apple.svg"
																			: "/images/icons/logo/🦆 icon _chrome_.svg"
							}
							alt="browser"
						/>
						<Typography variant="body2">{cellValues.value}</Typography>
					</ActionActions>
				);
			},
		},
		{
			field: "os",
			headerName: "OS",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<ImgTag
							src={
								cellValues.value === "Windows"
									? "/images/icons/logo/Windows 11.svg"
									: cellValues.value === "MacOS"
										? "/images/icons/logo/Apple (1).svg"
										: cellValues.value?.includes("iOS")
											? "/images/icons/logo/Apple.svg"
											: cellValues.value?.includes("macOS")
												? "/images/icons/logo/Apple (1).svg"
												: cellValues.value === "Linux"
													? "/images/icons/logo/Linux.svg"
													: cellValues.value?.includes("Android")
														? "/images/icons/logo/android.svg"
														: "/images/icons/logo/Windows 11.svg"
							}
							alt="os"
						/>
						<Typography variant="body2">{cellValues.value}</Typography>
					</ActionActions>
				);
			},
		},
		{
			field: "service_provider",
			headerName: "Service Provider",
			width: 150,
		},
		{
			field: "user_id",
			headerName: "User ID",
			width: 150,
		},
		{
			field: "userName",
			headerName: "User Name",
			width: 150,
		},
		{
			field: "search_query",
			headerName: "Applied Filters",
			width: 150,
			renderCell: (cellValues: any) => {
				return (
					<ActionActions>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								setOpenMoadal(true);
								setSearchQueryData(cellValues.value);
							}}
							sx={{ color: "#FFFFFF" }}
						>
							View
						</Button>
					</ActionActions>
				);
			},
		},
		{
			field: "createdAt",
			headerName: "Created At",
			width: 150,
		},
	];

	const GridToolbar = () => {
		return (
			<GridToolbarContainer>
				<GridToolbarColumnsButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarFilterButton
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarDensitySelector
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
				<GridToolbarExport
					slotProps={{
						button: {
							sx: { color: "#F76C35" },
						},
					}}
				/>
			</GridToolbarContainer>
		);
	};

	/*

  & Next, let's define a function to handle the click on the breadcrumbs

  */

	const handleClick = () => {};

	/*

  & Next, let's define the breadcrumbs

  */

	const breadcrumbs = [
		<Link
			underline="hover"
			key="1"
			color="inherit"
			href="/"
			onClick={handleClick}
		>
			Dashboard
		</Link>,
		<Typography
			key="3"
			color="text.primary"
		>
			Search Logs
		</Typography>,
	];

	return (
		<MainContainer>
			<SearchQueryModal
				open={openModal}
				onClose={() => {
					setOpenMoadal(false);
				}}
				bugdata={searchQueryData}
			/>
			<HeadContainer>
				<HeadText> Search Logs</HeadText>
				<Breadcrumbs
					separator={<NavigateNext fontSize="small" />}
					aria-label="breadcrumb"
					sx={{ margin: "0rem 0rem 0rem 1rem" }}
				>
					{breadcrumbs}
				</Breadcrumbs>
				<Divider sx={{ width: "100%", padding: "0.5rem 0rem 0.5rem 0rem" }} />
			</HeadContainer>
			{DashboardData.accessLevel &&
			((DashboardData.accessLevel.includes("admin") && DashboardData.accessLevel.includes("search_logs")) ||
				DashboardData.accessLevel.includes("super_admin")) ? (
				DashboardData.AnalyticsData || DashboardData.ActivityLogData ? null : (
					<Box sx={{ height: "80vh", padding: " 1rem", width: "100%" }}>
						<DataGridPremium
							disableRowSelectionOnClick={false}
							slots={{ toolbar: GridToolbar }}
							rows={DashboardData.DashboardData?.map((item: any, key: number) => {
								return {
									id: item ? item._id : key,
									_id: item ? item._id : "",
									search_duration: item ? (item.search_log ? item.search_log.search_duration : "") : "",
									result_count: item ? (item.search_log ? item.search_log.search_results_count : "") : "",
									location: item
										? item.search_log
											? item.search_log.search_query
												? item.search_log.search_query.location
												: ""
											: ""
										: "",
									search_type: item
										? item.search_log
											? item.search_log.search_query
												? item.search_log.search_query.searchType
												: "--"
											: "--"
										: "--",
									search_text: item
										? item.search_log
											? item.search_log.search_query
												? item.search_log.search_query.searchText
													? item.search_log.search_query.searchText
													: "--"
												: "--"
											: "--"
										: "--",
									transaction_type: item
										? item.search_log
											? item.search_log.search_query
												? item.search_log.search_query.transactionType
													? item.search_log.search_query.transactionType?.join(", ")
													: ""
												: ""
											: ""
										: "",
									ip_address: item
										? item.search_log
											? item.search_log.ip_stack_response
												? item.search_log.ip_stack_response.ip
												: ""
											: ""
										: "",
									browser: item
										? item.search_log
											? item.search_log.user_stack_response
												? item.search_log.user_stack_response.browser
													? item.search_log.user_stack_response.browser.name
													: ""
												: ""
											: ""
										: "",
									os: item
										? item.search_log
											? item.search_log.user_stack_response
												? item.search_log.user_stack_response.os
													? item.search_log.user_stack_response.os.name != null
														? item.search_log.user_stack_response.os.name
														: "Linux"
													: ""
												: ""
											: ""
										: "",
									service_provider: item
										? item.search_log
											? item.search_log.ip_stack_response
												? item.search_log.ip_stack_response.connection
													? item.search_log.ip_stack_response.connection.isp
														? item.search_log.ip_stack_response.connection.isp
														: "--"
													: "--"
												: "--"
											: ""
										: "",
									user_id: item
										? item.search_log
											? item.search_log.session_id
												? item.search_log.session_id._id
												: "--"
											: "--"
										: "--",
									userName: item
										? item.search_log
											? item.search_log.session_id
												? item.search_log.session_id.first_name + " " + item.search_log.session_id.last_name
												: "--"
											: "--"
										: "--",
									search_query: item ? (item.search_log ? item.search_log.search_query : "") : "",
									createdAt: item ? item.created_at : "",
								};
							})}
							getRowId={(row) => row.id}
							columns={columns}
							pagination={true}
							paginationMode="server"
							onPaginationModelChange={(paginationModel) => {
								setPaginationModel(paginationModel);
							}}
							rowCount={rowCountState}
							pageSizeOptions={[100]}
							sx={{
								backgroundColor: "#FFFFFF",
								"& > .MuiDataGrid-columnSeparator": {
									display: "none",
								},
								"& .MuiDataGrid-cell": {
									borderBottom: "none",
								},
							}}
						/>
					</Box>
				)
			) : (
				<YouDontHaveAccess>
					<Typography
						variant="h6"
						color="text.secondary"
					>
						You don&apos;t have access to this page
					</Typography>
				</YouDontHaveAccess>
			)}
		</MainContainer>
	);
};

/*

& FInally, let's export the component

*/

export default SearchLogDataTable;
